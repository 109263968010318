import React, { createContext, useContext, useMemo, useState } from 'react';


const LanguageContext = createContext({});

const LanguageContextProvider = ({ children }) => {
    const languages = useMemo(() => {
        return [
            {
                key: "en",
                name: "English",
                id: 0
            },
            {
                key: "he",
                name: "עברית",
                id: 1
            }
        ];
    }, []);
    const [selectedLanguage, setSelectedLanguage] = useState(0);
    const [enableChange, setEnableChange] = useState(false);

    const value = {
        languages,
        selectedLanguage,
        setSelectedLanguage,
        enableChange,
        setEnableChange
    }

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
}

const useLanguageContext = () => useContext(LanguageContext);

export { LanguageContextProvider, useLanguageContext };
