import React from "react";
import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import ErrorImage from "./Images/ErrorImage";


const PREFIX = 'ErrorMessage';

const classes = {
    dialogTitle: `${PREFIX}-dialogTitle`
};

const svgString = encodeURIComponent(renderToStaticMarkup(<ErrorImage />));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogTitle}`]: {
        width: theme.spacing(75),
        minWidth: theme.spacing(75),
        height: theme.spacing(40),
        minHeight: theme.spacing(40),
        backgroundImage: `url("data:image/svg+xml,${svgString}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0
    }
}));

const ErrorMessage = ({ onClose, retry }) => {
    const { t } = useTranslation();
    const email = "ITSupport@citybook.co.il";
    const phone = "089776040";

    return (
        <StyledDialog
            open={true}
            maxWidth="md"
            dir={t("dir")}
        >
            <DialogTitle className={classes.dialogTitle} />
            <DialogContent sx={{
                p: theme => theme.spacing(2, 3, 4)
            }}>
                <Typography
                    variant="h6"
                    sx={{
                        color: 'primary',
                        fontWeight: 550,
                        pt: 2,
                        pb: 2
                    }}
                >
                    {t("errorHeader")}
                </Typography>
                <Typography color="textSecondary">
                    {t("errorMsg")} <br />
                    {t("tryAgain")} <br />
                    {t("Need help? ")}
                    {t("contactIT")} <Link href={`mailto:${email}`} underline="hover">{email} </Link>
                    {t("or")} <Link href={`tel:${phone}`} underline="hover">{phone}</Link>
                </Typography>
            </DialogContent>
            <DialogActions sx={{
                mb: 2,
                pr: 3
            }}>
                <Button
                    onClick={onClose}
                >
                    {t('Close')}
                </Button>
                <Button
                    onClick={retry}
                    variant="contained"
                >
                    {t('Refresh')}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default ErrorMessage;
