import React, { useCallback, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Box, Button, Checkbox, CircularProgress, Grid, Skeleton, Typography } from "@mui/material";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import QuestionCard from "./QuestionCard";
import Instruction from "./Instruction";
import classNames from "classnames";
import { useAuth } from "../context/Auth";
import config from "../config";
import { useHistory } from "react-router-dom";
import useUnload from "../utils/useUnload";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../context/Language";
import ErrorMessage from "./ErrorMessage";


const PREFIX = 'Questions';

const classes = {
    header: `${PREFIX}-header`,
    subHeader: `${PREFIX}-subHeader`,
    questionsArea: `${PREFIX}-questionsArea`,
    question: `${PREFIX}-question`,
    checkbox: `${PREFIX}-checkbox`,
    checkboxIcon: `${PREFIX}-checkboxIcon`,
    uncheckedIcon: `${PREFIX}-uncheckedIcon`,
    submitWrapper: `${PREFIX}-submitWrapper`,
    submitButtonSkeleton: `${PREFIX}-submitButtonSkeleton`,
    instructionsHeader: `${PREFIX}-instructionsHeader`
};

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.header}`]: {
        color: theme.palette.grey[900]
    },
    [`& .${classes.subHeader}`]: {
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('xl')]: {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: theme.spacing(1.5),
        }
    },
    [`& .${classes.questionsArea}`]: {
        [theme.breakpoints.down('xl')]: {
            marginTop: theme.spacing(3.5)
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: theme.spacing(5.5)
        }
    },
    [`& .${classes.question}`]: {
        display: 'flex',
        alignItems: 'start'
    },
    [`& .${classes.checkbox}`]: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(2)
        },
        [theme.breakpoints.up('md')]: {
            marginRight: theme.spacing(4)
        }
    },
    [`& .${classes.checkboxIcon}`]: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        color: theme.palette.secondary.main
    },
    [`& .${classes.uncheckedIcon}`]: {
        opacity: 0.3
    },
    [`& .${classes.submitWrapper}`]: {
        textAlign: 'end'
    },
    [`& .${classes.submitButtonSkeleton}`]: {
        height: theme.spacing(4.5),
        width: theme.spacing(8.25),
        display: 'inline-flex'
    },
    [`& .${classes.instructionsHeader}`]: {
        color: theme.palette.text.primary,
        fontWeight: 550
    }
}));


const defaultUserQuestions = Array.from(Array(2).keys()).map(_ => {
    return {
        questionId: null,
        answer: "",
        isValid: false
    }
});

const QuestionsForm = ({ questions }) => {
    const { fetchWithUser } = useAuth();
    const [userQuestions, setUserQuestions] = useState(defaultUserQuestions);
    const [isSaving, setIsSaving] = useState(false);
    const [hasError, setHasError] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const { selectedLanguage, setEnableChange } = useLanguageContext();

    const isFormValid = userQuestions.every(q => q.isValid);

    useUnload(e => {
        e.preventDefault();
        e.returnValue = '';
    });

    const resetForm = useCallback(_ => setUserQuestions(defaultUserQuestions), []);

    useEffect(_ => {
        resetForm()
    }, [selectedLanguage, resetForm]);

    const getAvailableQuestions = (index) => {
        return questions.filter(q =>
            !userQuestions
                .filter((_, i) => i !== index)
                .find(u => u.questionId === q.id)
        );
    }

    const setUserQuestion = (index, data) => {
        let curr = [...userQuestions];
        curr[index] = data;
        setUserQuestions(curr);
    }

    const openSuccessPage = () => {
        history.push('/success');
    }

    const onSubmit = () => {
        setHasError(false);
        const body = {
            "answers":
                userQuestions.map(q => {
                    return {
                        "questionID": q.questionId,
                        "description": q.answer
                    }
                }),
            "language": t('languageKey')
        };
        const options = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        setIsSaving(true);
        setEnableChange(false);
        fetchWithUser(`${config.apiUrl}/Answers/CreateMultiple`, options)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                openSuccessPage();
            })
            .catch((e) => {
                console.log(e);
                setHasError(true);
            })
            .finally(() => {
                setIsSaving(false);
                setEnableChange(true);
            });
    }

    return (
        <Root>
            <Grid container>
                <Grid item
                    xs={12} md={6}
                    display="flex"
                    justifyContent="start"
                    sx={theme => ({
                        [theme.breakpoints.down('md')]: { px: 8 },
                        [theme.breakpoints.up('md')]: { pl: 8 },
                        [theme.breakpoints.up('lg')]: { px: 8 }
                    })}>
                    <Box
                        width="-webkit-fill-available"
                        sx={theme => ({
                            [theme.breakpoints.down('md')]: {
                                mt: 8
                            },
                            [theme.breakpoints.up('md')]: {
                                mt: 4
                            },
                            [theme.breakpoints.up('xl')]: {
                                mt: 8
                            }
                        })}
                    >
                        <Typography variant="h5" className={classes.header}>
                            {t("formHeader")}
                        </Typography>
                        <Typography variant="body1" className={classes.subHeader}>
                            {t("formSubHeader")}
                        </Typography>
                        <Box className={classes.questionsArea}>
                            {userQuestions.map((question, index) => {
                                return (
                                    <Box
                                        key={index}
                                        className={classes.question}
                                    >
                                        <Checkbox
                                            icon={<RadioButtonUnchecked className={classNames(classes.checkboxIcon, classes.uncheckedIcon)} />}
                                            checkedIcon={<CheckCircle className={classes.checkboxIcon} />}
                                            className={classes.checkbox}
                                            disabled
                                            checked={question.isValid}
                                        />
                                        <QuestionCard
                                            index={index}
                                            data={question}
                                            setData={setUserQuestion}
                                            questions={getAvailableQuestions(index)}
                                            isSaving={isSaving}
                                        />
                                    </Box>
                                )
                            })
                            }
                        </Box>
                        <Box className={classes.submitWrapper} >
                            {questions.length ?
                                <Button
                                    variant="contained"
                                    disabled={!isFormValid || isSaving}
                                    onClick={onSubmit}
                                    size="large"
                                >
                                    {isSaving ? <CircularProgress size={26} /> : t("submit")}
                                </Button>
                                : <Skeleton className={classes.submitButtonSkeleton} />
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    paddingX={8}
                >
                    <Box
                        sx={theme => ({
                            [theme.breakpoints.down('md')]: {
                                mt: 12,
                                backgroundColor: 'primary.lightBg',
                                p: theme.spacing(2.5, 5)
                            },
                            [theme.breakpoints.up('md')]: {
                                mt: 15
                            },
                            [theme.breakpoints.up('xl')]: {
                                mt: 22
                            }
                        })}
                    >
                        <Typography variant="h6" className={classes.instructionsHeader}>
                            {t("instructionsHeader")}
                        </Typography>
                        <Box
                            sx={theme => ({
                                [theme.breakpoints.down('md')]: {
                                    mt: 2
                                },
                                [theme.breakpoints.up('md')]: {
                                    mt: 4
                                },
                            })}
                        >
                            <Instruction text={t("callITInstruction")} index={1} />
                            <Instruction text={t("questionsInstruction")} index={2} />
                            <Instruction text={t("resetPasswordInstruction")} index={3} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {hasError && <ErrorMessage
                onClose={_ => setHasError(false)}
                retry={_ => window.location.reload()}
            />}
        </Root>
    );
};

export default QuestionsForm;
