import React from "react";
import { styled } from '@mui/material/styles';
import { Avatar, Box, Typography } from "@mui/material";

const PREFIX = 'Instruction';

const classes = {
    box: `${PREFIX}-box`,
    avatar: `${PREFIX}-avatar`,
    text: `${PREFIX}-text`
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`&.${classes.box}`]: {
        display: 'flex',
        alignItems: 'center',
        '&:not(:last-child)': {
            [theme.breakpoints.down('md')]: {
                marginBottom: theme.spacing(2)
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(3)
            }
        }
    },
    [`& .${classes.avatar}`]: {
        backgroundColor: theme.palette.primary.background,
        color: theme.palette.primary.main,
        marginRight: theme.spacing(3),
        height: theme.spacing(6),
        width: theme.spacing(6)
    },
    [`& .${classes.text}`]: {
        color: theme.palette.text.primary
    }
}));

const Instruction = ({ index, text }) => {
    return (
        <StyledBox className={classes.box}>
            <Avatar className={classes.avatar}>
                {index}
            </Avatar>
            <Typography variant="subtitle1" className={classes.text}>
                {text}
            </Typography>
        </StyledBox>
    );
};

export default Instruction;
