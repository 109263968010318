import React, { useEffect, useState } from "react";
import config from "../config";
import { useAuth } from "../context/Auth";
import ConfirmUpdateForm from "./ConfirmUpdateForm";
import Loader from "./Loader";
import QuestionsForm from "./QuestionsForm";
import i18next from "i18next";
import { useLanguageContext } from "../context/Language";
import { useCurrentUser } from "../context/CurrentUser";


const Questions = () => {
    const { fetchWithUser, logout } = useAuth();
    const [questions, setQuestions] = useState([]);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [isEditableForm, setIsEditableForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const { languages, setSelectedLanguage, setEnableChange } = useLanguageContext();
    const { currentUser } = useCurrentUser();

    useEffect(_ => {
        if (currentUser) {
            var language = languages.find(l => l.id === currentUser.languageId);
            i18next.changeLanguage(language?.key || 'en');
            setSelectedLanguage(language?.id);
            if (currentUser.statusId === 1) {
                setOpenConfirmModal(true)
                setIsEditableForm(false);
            }
            else setIsEditableForm(true);
        }
    }, [currentUser, languages, setSelectedLanguage]);

    useEffect(() => {
        currentUser &&
            (async () => {
                await fetchWithUser(config.apiUrl + '/Question/GetAllEnabled')
                    .then(res => res.json())
                    .then(data => {
                        setQuestions(data);
                    })
                    .finally(_ => {
                        setLoading(false);
                        setEnableChange(true);
                    })
            })();
    }, [currentUser, fetchWithUser, setEnableChange]);

    const onConfirmModal = _ => {
        setIsEditableForm(true);
        setOpenConfirmModal(false);
    }

    if (loading)
        return <Loader />

    if (openConfirmModal)
        return <ConfirmUpdateForm
            onConfirm={onConfirmModal}
            onCancel={_ => logout()}
        />

    return (
        isEditableForm && <QuestionsForm questions={questions} />
    );
};

export default Questions;
