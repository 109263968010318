import React from "react";
import { styled } from '@mui/material/styles';
import { Backdrop, Box, Card, CardContent } from "@mui/material";
import LoginButton from "./LoginButton";


const PREFIX = 'LoginPopup';

const classes = {
    backdrop: `${PREFIX}-backdrop`,
    card: `${PREFIX}-card`
};

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    [`&.${classes.backdrop}`]: {
        position: 'absolute',
        zIndex: theme.zIndex.modal + 1
    },
    [`& .${classes.card}`]: {
        position: 'relative',
        overflow: 'auto'
    }
}));

const LoginPopup = () => {
    return (
        <StyledBackdrop open={true} className={classes.backdrop}>
            <Card variant="outlined" elevation={4} className={classes.card}>
                <CardContent>
                    <Box justifyContent="space-around">
                        <Box>Your session in ended.</Box>
                    </Box>
                    <LoginButton variant="contained" />
                </CardContent>
            </Card>
        </StyledBackdrop>
    );
};

export default LoginPopup;
