import React, { createContext, useContext, useState } from 'react';


const CurrentUserContext = createContext({});

const CurrentUserContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(0);

    const value = {
        currentUser,
        setCurrentUser
    }

    return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
}

const useCurrentUser = () => useContext(CurrentUserContext);

export { CurrentUserContextProvider, useCurrentUser };
