import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import HeaderNavbar from './components/HeaderNavbar';
import Loader from "./components/Loader";
import Login from "./components/Login";
import Logout from "./components/Logout";
import ProtectedRoute from './components/ProtectedRoute';
import Styles from './components/Providers/Styles';
import Questions from './components/Questions';
import Success from './components/Success';
import { AuthContextProvider } from './context/Auth';
import DirectionWrapper from './components/Providers/DirectionWrapper';
import { LanguageContextProvider } from './context/Language';
import { CurrentUserContextProvider } from './context/CurrentUser';


function App() {
  return (
    <div className="App">
      <Styles>
        <AuthContextProvider>
          <BrowserRouter>
            <CurrentUserContextProvider>
              <LanguageContextProvider>
                <HeaderNavbar />
                <DirectionWrapper>
                  <Switch>
                    <ProtectedRoute path="/questions">
                      <Suspense fallback={<Loader />}>
                        <Questions />
                      </Suspense>
                    </ProtectedRoute>
                    <ProtectedRoute path="/success">
                      <Suspense fallback={<Loader />}>
                        <Success />
                      </Suspense>
                    </ProtectedRoute>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <Route path="/logout">
                      <Logout />
                    </Route>
                    <Redirect exact from="/" to="/questions" />
                  </Switch>
                </DirectionWrapper>
              </LanguageContextProvider>
            </CurrentUserContextProvider>
          </BrowserRouter>
        </AuthContextProvider>
      </Styles>
    </div>
  );
}

export default App;
