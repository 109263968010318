import React, {
    useState,
    useReducer,
    useContext,
    useEffect,
    useCallback,
    createContext
} from 'react';
import { Logger, UserAgentApplication } from 'msal';
import config from '../config';
import popupWindow from '../utils/popupWindow';
import fetcher from '../utils/fetcher';

const reducer = (state, action) => {
    switch (action.type) {
        case 'DID_LOGOUT':
            return {
                ...state,
                isAuth: false,
                isMsalAuth: false,
                user: null
            };
        case 'WILL_LOGIN':
            return { ...state, isLoggingIn: true, loginError: '' };
        case 'DID_LOGIN':
            return {
                ...state,
                isMsalAuth: true,
                isFetchingUser: true
            };
        case 'DID_LOGIN_ERROR':
            return {
                ...state,
                isLoggingIn: false,
                isAuth: false,
                isMsalAuth: false,
                loginError: action.data
            };
        case 'TOKEN_DID_ACQUIRED':
            return { ...state, isAuth: true, isLoggingIn: false, shouldLogout: false }
        case 'USER_WILL_FETCH':
            return { ...state, isFetchingUser: true };
        case 'USER_DID_FETCH':
            return { ...state, user: action.data, isFetchingUser: false };
        case 'USER_DID_FETCH_ERROR':
            return { ...state, isFetchingUser: false };
        case 'SHOULD_LOGOUT':
            return {
                ...state,
                shouldLogout: true,
                isAuth: false,
                isMsalAuth: false,
                isLoggingIn: false,
                tokenError: action.data
            };
        default:
            return state;
    }
}

const initialState = {
    isMsalAuth: false,
    isAuth: false,
    isLoggingIn: false,
    isFetchingUser: false,
    user: null,
    loginError: '',
    tokenError: '',
    shouldLogout: false
}

const scope = {
    graph: ['user.read'],
    api: [config.authConfig.apiScope]
}

const AuthContext = createContext({})

const requestState = JSON.stringify({
    redirectUrl: typeof window !== 'undefined' ? window.location.origin + '/msal.html' : ''
});

const AuthContextProvider = ({ children }) => {
    const [msal] = useState(() => {
        return new UserAgentApplication({
            auth: {
                redirectUri: config.authConfig.redirectUrl,
                postLogoutRedirectUri: window.location.href,
                ...config.authConfig
            },
            cache: { cacheLocation: 'localStorage' },
            system: {
                logger: new Logger((level, message) => {
                    if (!config.isProduction) console.log('[msal]', level, message);
                })
            }
        });
    })

    const [
        {
            isAuth,
            isLoggingIn,
            isFetchingUser,
            user,
            loginError,
            shouldLogout,
            isMsalAuth
        },
        dispatch
    ] = useReducer(reducer, {
        ...initialState,
        isMsalAuth: msal && !!msal.getAccount(),
        isFetchingUser: msal && !!msal.getAccount()
    })

    const login = useCallback(async () => {
        dispatch({ type: 'WILL_LOGIN' })
        try {
            // Login via popup
            await msal.loginPopup({
                scopes: scope.graph,
                prompt: 'select_account',
                state: requestState
            })
            dispatch({ type: 'DID_LOGIN' })
        } catch (err) {
            dispatch({ type: 'DID_LOGIN_ERROR', data: err.errorMessage })
        }
    }, [msal])

    const logout = () => {
        if (window.opener && window.opener !== window) return msal.logout()

        const logoutWin = popupWindow('/logout', '', 500, 600)
        if (logoutWin)
            logoutWin.onbeforeunload = () => dispatch({ type: 'DID_LOGOUT' })
    }

    const getAccessToken = useCallback(
        async (scopes) => {
            const tokenrequest = {
                scopes: scopes,
                state: requestState
            }
            try {
                // Get the access token silently
                // If the cache contains a non-expired token, this function
                // will just return the cached token. Otherwise, it will
                // make a request to the Azure OAuth endpoint to get a token
                var silentResult = await msal.acquireTokenSilent(tokenrequest)
                return silentResult.accessToken
            } catch (err) {
                // If a silent request fails, it may be because the user needs
                // to login or grant consent to one or more of the requested scopes
                if (
                    err.name === 'InteractionRequiredAuthError' ||
                    isInteractionRequired(err)
                ) {
                    try {
                        var interactiveResult = await msal.acquireTokenPopup(tokenrequest)

                        return interactiveResult.accessToken
                    } catch {
                        dispatch({ type: 'SHOULD_LOGOUT', data: err })
                        return ''
                    }
                } else {
                    dispatch({ type: 'SHOULD_LOGOUT', data: err })
                    return ''
                }
            }
        },
        [msal]
    )

    const fetchWithUser = useCallback(
        async (
            endpoint,
            options = {},
            scopes = scope.api
        ) => {
            var accessToken = await getAccessToken(scopes)

            const headers = {
                ...options.headers,
                Authorization: `Bearer ${accessToken}`
            }
            const mergedOpts = { ...options, headers }

            return fetch(endpoint, mergedOpts).then(response => {
                if (!response.ok) {
                    return Promise.reject(response)
                }
                return response
            })
        },
        [getAccessToken]
    )

    const getUserProfile = useCallback(async () => {
        dispatch({ type: 'USER_WILL_FETCH' })
        const res = await fetchWithUser(
            'https://graph.microsoft.com/v1.0/me',
            {},
            scope.graph
        )
        const { error, ...user } = await res.json()

        if (error) return dispatch({ type: 'USER_DID_FETCH_ERROR', data: error })
        return dispatch({ type: 'USER_DID_FETCH', data: user })
    }, [fetchWithUser])

    const addAuthenticationHeader = useCallback(() => {
        fetcher.use(async options => {
            var accessToken = await getAccessToken(scope.api)
            const headers = {
                ...options.headers,
                Authorization: `Bearer ${accessToken}`
            }
            return { ...options, headers }
        })
    }, [getAccessToken])

    const isInteractionRequired = (error) => {
        if (!error.message || error.message.length <= 0) {
            return false
        }

        return (
            error.message.indexOf('consent_required') > -1 ||
            error.message.indexOf('interaction_required') > -1 ||
            error.message.indexOf('login_required') > -1
        )
    }

    useEffect(() => {
        if (isAuth) addAuthenticationHeader()
    }, [isAuth, addAuthenticationHeader])

    useEffect(() => {
        if (isAuth) getUserProfile()
    }, [isAuth, getUserProfile])

    useEffect(() => {
        (async () => {
            isMsalAuth &&
                await getAccessToken(scope.graph) &&
                dispatch({ type: 'TOKEN_DID_ACQUIRED' })
        })()
    }, [isMsalAuth, getAccessToken])

    const value = {
        user,
        isMsalAuth,
        isAuth,
        login,
        logout,
        isLoggingIn,
        isFetchingUser,
        fetchWithUser: fetchWithUser,
        loginError,
        shouldLogout
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export { AuthContextProvider, useAuth }
