import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Autocomplete, ListItem, Paper, Popper, Skeleton, TextField, Typography } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from 'react-i18next';


const PREFIX = 'QuestionCard';

const classes = {
    root: `${PREFIX}-root`,
    selectedCard: `${PREFIX}-selectedCard`,
    header: `${PREFIX}-header`,
    selectedHeader: `${PREFIX}-selectedHeader`,
    formControlSkeleton: `${PREFIX}-formControlSkeleton`,
    questions: `${PREFIX}-questions`,
    popper: `${PREFIX}-popper`
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    [`&.${classes.popper}`]: {
        zIndex: theme.zIndex.appBar - 1
    }
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: theme.spacing(3, 5, 6, 4.5),
        boxShadow: '0px 11px 14px -7px rgba(0, 0, 0, 0.05), 0px 23px 36px 3px rgba(0, 0, 0, 0.035), 0px 6px 44px 19px rgba(0, 0, 0, 0.03)',
        [theme.breakpoints.up('xl')]: {
            marginBottom: theme.spacing(4),
            padding: theme.spacing(3, 5, 6, 4.5)
        },
        [theme.breakpoints.down('xl')]: {
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2, 5, 2, 4.5)
        },
        [theme.breakpoints.up('md')]: {
            width: '35vw'
        },
        [theme.breakpoints.down('md')]: {
            width: '-webkit-fill-available'
        },
        borderLeft: `${theme.spacing(0.5)} ${theme.palette.common.white} solid`
    },
    [`&.${classes.selectedCard}`]: {
        borderLeftColor: theme.palette.secondary.main
    },
    [`& .${classes.header}`]: {
        fontWeight: 550,
        [theme.breakpoints.down('xl')]: {
            marginBottom: theme.spacing(1.5),
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: theme.spacing(3),
        }
    },
    [`& .${classes.selectedHeader}`]: {
        color: theme.palette.secondary.dark
    },
    [`& .${classes.formControlSkeleton}`]: {
        height: theme.spacing(7)
    },
    [`& .${classes.questions}`]: {
        [theme.breakpoints.down('xl')]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: theme.spacing(3),
        },
    }
}));

const QuestionCard = ({ index, data, setData, questions, isSaving }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [answerHasError, setAnswerHasError] = useState(false);
    const { t } = useTranslation();

    const getSelectedIndex = _ => {
        return data.questionId ? questions.find(q => q.id === data.questionId) : null;
    }

    const onQuestionSelected = (question) => {
        setData(index, {
            ...data,
            answer: "",
            questionId: question?.id,
            isValid: false
        });
    }

    const onAnswerChanged = (answer) => {
        let length = answer.replace(/[^0-9a-zA-Z֐-׾]/gi, '').length;
        setData(index, {
            ...data,
            answer: answer,
            isValid: length > 1 && data.questionId !== null
        });
        setAnswerHasError(length < 2);
    }

    useEffect(_ => {
        if (!data?.questionId)
            setAnswerHasError(false);
    }, [data?.questionId]);

    return (
        <StyledPaper
            className={classNames(classes.root, isFocused && classes.selectedCard)}
            onMouseEnter={_ => setIsFocused(true)}
            onMouseLeave={_ => setIsFocused(false)}
        >
            <Typography
                variant="h6"
                className={classNames(classes.header, isFocused && classes.selectedHeader)}
            >
                {t("cardHeader")} {index + 1}
            </Typography>
            {questions.length ?
                <Autocomplete
                    value={getSelectedIndex()}
                    onChange={(_, newValue) => onQuestionSelected(newValue)}
                    fullWidth
                    options={questions}
                    getOptionLabel={question => question[t('questionDescriptionColumn')]}
                    includeInputInList
                    disablePortal
                    renderInput={props =>
                        <TextField
                            {...props}
                            label={t('questionTitle')}
                            required
                        />
                    }
                    renderOption={(props, option) => (
                        <ListItem
                            {...props}
                            key={option.id}
                            dir={t("dir")}
                        >
                            {option[t('questionDescriptionColumn')]}
                        </ListItem>
                    )}
                    disabled={isSaving}
                    openText={t('Open')}
                    clearText={t('Clear')}
                    closeText={t('Close')}
                    loadingText={t('Loading…')}
                    noOptionsText={t('No options')}
                    ListboxProps={{
                        sx: {
                            maxHeight: (theme) => theme.spacing(24.5),
                            '&::-webkit-scrollbar': {
                                width: '0.4em',
                                background: '#F9F9F9',
                                boxShadow: 'inset 1px 0px 0px #dedede',
                                borderRadius: (theme) => theme.spacing(0.5)
                            },
                            '&::-webkit-scrollbar-track': {
                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'text.secondary',
                                borderRadius: (theme) => theme.spacing(0.5)
                            }
                        }
                    }}
                    PopperComponent={props => <StyledPopper {...props} className={classes.popper} />}
                    classes={{
                        root: classes.questions
                    }}
                />
                : <Skeleton className={classes.formControlSkeleton} />
            }
            {questions.length ?
                <TextField
                    error={answerHasError}
                    disabled={isSaving}
                    required
                    label={t('answerTitle')}
                    value={data.answer}
                    helperText={answerHasError && t('answerHelperText')}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => onAnswerChanged(e.target.value)}
                />
                : <Skeleton className={classes.formControlSkeleton} />
            }
        </StyledPaper>
    )
};

export default QuestionCard;
