import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from '../context/Auth';
import { Box, Typography } from '@mui/material';
import LoginButton from './LoginButton';
import SecurityIcon from './Icons/SecurityIcon';
import HomeBGImage from './Images/HomeBGImage';
import { renderToStaticMarkup } from 'react-dom/server';


const svgString = encodeURIComponent(renderToStaticMarkup(<HomeBGImage />));

const Login = () => {
    const location = useLocation();
    const { isAuth } = useAuth();

    const { from } = (location.state) || { from: { pathname: '/' } };

    // if we have a user, we shouldn't be at this url
    if (isAuth) return <Redirect to={from} />

    return (
        <Box
            sx={theme => ({
                backgroundColor: theme.palette.custom.background,
                textAlign: 'center',
                height: 'calc(100vh - 64px)',
                backgroundImage: `url("data:image/svg+xml,${svgString}")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            })}
        >
            <Box
                sx={theme => ({
                    [theme.breakpoints.down('xl')]: {
                        pt: 22
                    },
                    [theme.breakpoints.up('xl')]: {
                        pt: 35
                    }
                })}
            >
                <SecurityIcon size={200} />
            </Box>
            <Typography
                variant="h2"
                sx={{
                    color: theme => theme.palette.primary.contrastText,
                    mt: 6
                }}
            >
                Stay safe with CityPass
            </Typography>
            <LoginButton variant="contained" />
        </Box>
    );
};

export default Login;
