import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router';
import config from '../config';
import { useAuth } from '../context/Auth';
import { useCurrentUser } from '../context/CurrentUser';
import Loader from './Loader';
import LoginPopup from './LoginPopup';
import Unregistered from './Unregistered';

const ProtectedRoute = ({ children, ...rest }) => {
    const { isAuth, isMsalAuth, shouldLogout, fetchWithUser } = useAuth();
    const { setCurrentUser } = useCurrentUser();
    const [isRegistered, setIsRegistered] = useState();

    useEffect(_ => {
        (async () => {
            await fetchWithUser(config.apiUrl + '/Users/GetCurrentUser')
                .then(res => res.json())
                .then(user => {
                    setCurrentUser(user);
                    setIsRegistered(user !== null);
                })
                .catch(_ => {
                    setCurrentUser(null);
                    setIsRegistered(false);
                })
        })();
    }, [fetchWithUser, setCurrentUser]);

    const components = children ? (
        <Route {...rest}>{children}</Route>
    ) : (
        <Route {...rest} />
    );

    if (shouldLogout)
        return (
            <>
                {components}
                <LoginPopup />
            </>
        );

    if (isAuth) {
        if (typeof isRegistered !== 'boolean')
            return <Loader />;
        if (!isRegistered)
            return <Unregistered />
        else
            return components;
    }

    if (isMsalAuth) {
        return <Loader />
    }

    return (
        <Redirect
            to={{
                pathname: '/login',
                state: { from: rest.location }
            }}
        />
    )
};

export default ProtectedRoute;
