import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Menu,
    MenuItem,
    Button,
    Grid
} from '@mui/material';
import { ArrowDropDown } from "@mui/icons-material";
import { useAuth } from "../context/Auth";
import SecurityIcon from "./Icons/SecurityIcon";
import Title from "./Icons/TitleIcon";
import { useLocation } from "react-router";
import LanguageMenu from "./LanguageMenu";
import { useLanguageContext } from "../context/Language";


const HeaderNavbar = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { user, login, logout } = useAuth();
    const { enableChange } = useLanguageContext();
    const location = useLocation();
    const contrastColors = location.pathname === '/login';

    useEffect(_ => {
        setAnchorEl(null);
    }, [user])

    const onUserButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const onLoginClick = () => {
        login();
        setAnchorEl(null);
    }

    const onLogoutClick = () => {
        logout();
        setAnchorEl(null);
    }

    const onCloseMenu = () => {
        setAnchorEl(null);
    }

    return (
        <AppBar
            position="sticky"
            sx={theme => ({
                ...(contrastColors ?
                    {
                        backgroundColor: theme.palette.primary.contrastText
                    } :
                    {
                        backgroundColor: theme.palette.custom.background
                    }),
                boxShadow: 'none'
            })}
        >
            <Toolbar>
                <Grid container>
                    <Grid item xs={6} container display="flex" alignItems="center">
                        <SecurityIcon size={24} />
                        <Box
                            sx={{
                                display: "inline-flex",
                                ml: 2
                            }}
                        >
                            <Title fill={contrastColors && '#2C4FEC'} />
                        </Box>
                    </Grid>
                    <Grid item xs={6} container display="flex" alignItems="center" justifyContent="flex-end">
                        <LanguageMenu />
                        {user ?
                            <Box sx={{ ml: 3 }}>
                                <Button
                                    sx={{
                                        color: theme => theme.palette.primary.contrastText
                                    }}
                                    onClick={onUserButtonClick}
                                    disabled={!enableChange}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: theme => theme.spacing(1.875),
                                            mr: 1,
                                            letterSpacing: '0.46px'
                                        }}
                                    >
                                        Hi, {user?.givenName}!
                                    </Typography>
                                    <ArrowDropDown />
                                </Button>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={onCloseMenu}
                                >
                                    <MenuItem onClick={onLogoutClick}>{'Logout'}</MenuItem>
                                </Menu>
                            </Box>
                            :
                            <MenuItem
                                onClick={onLoginClick}
                                sx={({
                                    ...(contrastColors && {
                                        color: theme => theme.palette.custom.background
                                    }),
                                    fontWeight: 550
                                })}
                            >
                                Login
                            </MenuItem>
                        }
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default HeaderNavbar;
