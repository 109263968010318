import React from 'react';
import { Backdrop, Box, CircularProgress, Grid, Typography } from '@mui/material';


const Loader = ({ message, color, size }) => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Backdrop
                sx={{ background: 'transparent' }}
                open={true}
            >
                <Box color={color ? color : 'primary'}>
                    <CircularProgress
                        color={color ? 'inherit' : 'primary'}
                        size={size || 40}
                    />
                </Box>
                <Box color={color ? color : 'inherit'}>
                    <Typography color="inherit">{message}</Typography>
                </Box>
            </Backdrop>
        </Grid>
    );
};

export default Loader;
