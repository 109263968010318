import React, { useState } from "react";
import { Language } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import { useLanguageContext } from "../context/Language";


const LanguageMenu = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const { t } = useTranslation();
    const { languages, selectedLanguage, setSelectedLanguage, enableChange } = useLanguageContext();

    const open = Boolean(anchorEl);

    const onMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const onLanguageChange = (language) => {
        setSelectedLanguage(language.id);
        i18next.changeLanguage(language.key);
        onMenuClose();
    }

    return (
        <div>
            <Button
                id="language-button"
                aria-controls={open ? 'language-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={onMenuOpen}
                sx={{ color: 'primary.contrastText' }}
                disabled={!enableChange}
            >
                <Language />
                <Typography sx={{ ml: 1 }}>{t('languageCode')}</Typography>
            </Button>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={onMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'language-button',
                }}
            >
                {Object.entries(languages).map(([key, language]) =>
                    <MenuItem
                        key={key}
                        onClick={_ => onLanguageChange(language)}
                        selected={language.id === selectedLanguage}
                    >
                        {language.name}
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
};

export default LanguageMenu;
