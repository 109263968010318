import React from "react";
import { styled } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { grey } from '@mui/material/colors';
import { renderToStaticMarkup } from "react-dom/server";
import RefillFormImage from "./Images/RefillFormImage";
import { useTranslation } from "react-i18next";


const PREFIX = 'ConfirmUpdateForm';

const classes = {
    dialogTitle: `${PREFIX}-dialogTitle`
};

const svgString = encodeURIComponent(renderToStaticMarkup(<RefillFormImage />));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    [`& .${classes.dialogTitle}`]: {
        width: theme.spacing(75),
        minWidth: theme.spacing(75),
        height: theme.spacing(40),
        minHeight: theme.spacing(40),
        backgroundImage: `url("data:image/svg+xml,${svgString}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        padding: 0
    }
}));

const ConfirmUpdateForm = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation();

    return (
        <StyledDialog
            open={true}
            maxWidth="md"
            dir={t("dir")}
        >
            <DialogTitle className={classes.dialogTitle} />
            <DialogContent sx={{ p: theme => theme.spacing(1, 3, 2) }}>
                <Typography
                    variant="h6"
                    align="center"
                    color={grey[900]}
                    sx={{ pt: 2, pb: 2 }}
                >
                    {t("confirmHeader")}
                </Typography>
                <Typography color="textSecondary" align="center">
                    {t("We saved all your questions and answers.")} <br />
                    {t("Due to security reasons, they won't be displayed,")} <br />
                    {t("you can override them.")} <br/>
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", mb: 3 }}>
                <Button
                    onClick={onCancel}
                >
                    {t("No, Close")}
                </Button>
                <Button
                    onClick={onConfirm}
                    variant='contained'
                >
                    {t("Yes, Rewrite")}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default ConfirmUpdateForm;
