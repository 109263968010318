import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material';


const EmotionRtl = ({ children }) => {

    const { t } = useTranslation();

    const isRtl = t("dir") === "rtl";
    const cacheLtr = createCache({
        key: "muiltr"
    });

    const cacheRtl = createCache({
        key: "muirtl",
        stylisPlugins: [rtlPlugin]
    });

    return (
        <div dir={t("dir")}>
            <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
                <ThemeProvider
                    theme={(theme) =>
                        createTheme({
                            ...theme,
                            direction: t("dir")
                        })
                    }
                >
                    {children}
                </ThemeProvider>
            </CacheProvider>
        </div>
    );
}

export default EmotionRtl;
