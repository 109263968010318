import React from "react";
import { styled } from '@mui/material/styles';
import { Button, CircularProgress } from "@mui/material";
import MicrosoftIcon from "./Icons/MicrosoftIcon";
import classNames from "classnames";
import { useAuth } from "../context/Auth";

const PREFIX = 'LoginButton';

const classes = {
    submit: `${PREFIX}-submit`
};

const StyledButton = styled(Button)(({ theme }) => ({
    [`&.${classes.submit}`]: {
        padding: theme.spacing(1, 4),
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.dark
    }
}));

const LoginButton = ({ variant = "outlined" }) => {
    const { login, isLoggingIn } = useAuth();

    return (
        <StyledButton
            type="submit"
            variant={variant}
            className={classNames(classes.submit)}
            onClick={() => login()}
            startIcon={<MicrosoftIcon />}
            endIcon={
                isLoggingIn && (
                    <CircularProgress
                        size={24}
                    />
                )
            }
        >
            Sign In with Microsoft
        </StyledButton>
    );
};

export default LoginButton;