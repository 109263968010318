import React from "react";
import { Backdrop, Card, CardContent, Typography } from "@mui/material";
import SecurityIcon from "./Icons/SecurityIcon";
import { useTranslation } from 'react-i18next';
import { grey } from "@mui/material/colors";


const Success = () => {

    const { t } = useTranslation();

    return (
        <Backdrop
            open={true}
            sx={{ background: 'transparent' }}
        >
            <Card
                sx={theme => ({
                    width: theme.spacing(75),
                    minWidth: theme.spacing(75),
                    boxShadow: theme.shadows[16]
                })}
            >
                <CardContent
                    sx={{
                        textAlign: 'center',
                        p: theme => theme.spacing(9, 15, 6)
                    }}
                >
                    <SecurityIcon size={120} />
                    <Typography
                        variant="h5"
                        sx={{
                            color: grey[900],
                            m: theme => theme.spacing(2.75, 0)
                        }}
                    >
                        {t("accountSecure")}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'text.secondary',
                            mb: 2.75
                        }}
                    >
                        {t("successMessage")}
                    </Typography>
                </CardContent>
            </Card>
        </Backdrop>
    );
};

export default Success;
