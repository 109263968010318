const config = {
    authConfig: {
        clientId: process.env.REACT_APP_AAD_CLIENT_ID || '',
        authority: process.env.REACT_APP_AAD_AUTHORITY || '',
        redirectUrl: process.env.REACT_APP_AAD_REDIRECT_URL || '',
        apiScope: process.env.REACT_APP_AAD_API_SCOPE || ''
    },
    apiUrl: process.env.REACT_APP_API || ''
}

export default config;