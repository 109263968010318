import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Typography } from "@mui/material";
import { renderToStaticMarkup } from "react-dom/server";
import LockedImage from "./Images/LockedImage";
import { useAuth } from "../context/Auth";
import { Close } from "@mui/icons-material";
import { grey } from "@mui/material/colors";


const svgString = encodeURIComponent(renderToStaticMarkup(<LockedImage />));

const Unregistered = () => {
    const { logout } = useAuth();

    const onClose = _ => {
        logout();
    }

    const email = "ITSupport@citybook.co.il";

    return (
        <Dialog
            open={true}
            maxWidth="md"
        >
            <DialogTitle
                sx={theme => ({
                    width: theme.spacing(75),
                    minWidth: theme.spacing(75),
                    height: theme.spacing(40),
                    minHeight: theme.spacing(40),
                    backgroundImage: `url("data:image/svg+xml,${svgString}")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    p: 0
                })}
            >
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: theme => theme.spacing(1, 3, 0) }}>
                <Typography
                    variant="h6"
                    align="center"
                    sx={{
                        fontWeight: 550,
                        p: theme => theme.spacing(1, 0)
                    }}
                >
                    Access Denied
                </Typography>
                <Typography
                    color="textSecondary"
                    align="center"
                    sx={{ p: 1 }}
                >
                    For some reason CityPass could not identify you. <br />
                    Please contact the IT support at <Link href={`mailto:${email}`} underline="hover">{email}</Link>
                </Typography>
            </DialogContent>
            <DialogActions
                sx={{
                    p: theme => theme.spacing(1, 3, 4),
                    justifyContent: 'center'
                }}
            >
                <Button
                    autoFocus
                    onClick={onClose}
                    color="primary"
                >
                    Login with a different account
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Unregistered;
